import React from "react";
import PageContainer from "./pages";
import './App.scss';
import './font.scss';

const App: React.FC<any> = () => {
  return (
      <div>
        <PageContainer />
      </div>
  );
}

export default App;
