import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface AuthState {
    authenticated: boolean
}

const initialState: AuthState = {
    authenticated: false
};


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateAuthAction: (state, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload
        }
    }
});

export const { updateAuthAction } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthStatus = (state: RootState) => state.auth.authenticated;

export default authSlice.reducer;
