import React from 'react';
import BookList from '../../components/BookList';

const HomePage: React.FC<any> = () => {
    return (
        <div>
            <BookList />
        </div>
    )
}

export default HomePage;