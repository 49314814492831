import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBook } from '../../models/Book';
import { IBaseListResponse } from '../../models/Common';
import { AppConfig } from '../../config';
import axios from 'axios';
import { RootState } from '../../app/store';

export interface BookListState {
    response?: IBaseListResponse<IBook>
    list: IBook[]
    loading: boolean
}

const initialState: BookListState = {
    list: [],
    loading: false,
};

export const fetchBookListAction = createAsyncThunk(
    'bookList/fetch',
    async (test: number, {rejectWithValue }) => {
        try {
            const response = await axios.get<IBaseListResponse<IBook>>(`${AppConfig.truyenApi}/books`, {
                headers: {
                    token: localStorage.getItem('authentication')
                }
            })
            return response.data;    
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
  )

export const bookListSlice = createSlice({
    name: 'bookList',
    initialState,
    reducers: {
        // increaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = add(state.lineHeightPixel, action.payload);
        // },
        // decreaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
        // },
        // increaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = add(state.fontSizePixel, action.payload);
        // },
        // decreaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
        // },
        // increaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
        // },
        // decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBookListAction.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(fetchBookListAction.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.list = action.payload.result.items;
        })
        builder.addCase(fetchBookListAction.rejected, (state, action) => {
            state.loading = false;
            state.response = undefined;
            state.list = [];
        })
      },
});

export const selectBookList = (state: RootState) => state.bookList.list;
export const selectBookListLoading = (state: RootState) => state.bookList.loading;

export default bookListSlice.reducer;
