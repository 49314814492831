import React, { useState } from 'react';
import { IBook } from '../../../models/Book';
import styles from './styles.module.scss';

interface Props {
    bookDetail: IBook;
}

const BookWriterInfo: React.FC<Props> = ({ bookDetail }) => {
    const [showPopup, updatePopupState] = useState(false)
    return <div className={styles.container}>
            <div className={styles.name} onClick={() => updatePopupState(!showPopup)}>{bookDetail.writer.name}</div>
            {showPopup && <div className={styles.popUpInfo}>
                <div>{bookDetail.writer.name}</div>
                <div>{bookDetail.writer.description}</div>
                <div>{bookDetail.writer.country.name}</div>
            </div>}
        </div>
}

export default BookWriterInfo;