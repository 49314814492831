import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IWriter } from '../../models/Writer';
import { IBaseGetResponse } from '../../models/Common';
import { AppConfig } from '../../config';
import axios from 'axios';
import { RootState } from '../../app/store';

export interface WriterDetailState {
    response?: IBaseGetResponse<IWriter>
    detail?: IWriter
    loading: boolean
}

const initialState: WriterDetailState = {
    loading: false,
};

export const fetchWriterDetailAction = createAsyncThunk(
    'writerDetail/fetch',
    async (writerId: string, {rejectWithValue }) => {
        try {
            const response = await axios.get<IBaseGetResponse<IWriter>>(`${AppConfig.truyenApi}/writers/${writerId}`, {
                headers: {
                    token: localStorage.getItem('authentication')
                }
            })
            return response.data;    
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
  )

export const writerDetailSlice = createSlice({
    name: 'writerDetail',
    initialState,
    reducers: {
        // increaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = add(state.lineHeightPixel, action.payload);
        // },
        // decreaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
        // },
        // increaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = add(state.fontSizePixel, action.payload);
        // },
        // decreaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
        // },
        // increaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
        // },
        // decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWriterDetailAction.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(fetchWriterDetailAction.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.detail = action.payload.result;
        })
        builder.addCase(fetchWriterDetailAction.rejected, (state, action) => {
            state.loading = false;
            state.response = undefined;
            state.detail = undefined;
        })
      },
});

export const selectWriterDetail = (state: RootState) => state.writerDetail.detail;
export const selectWriterDetailLoading = (state: RootState) => state.writerDetail.loading;

export default writerDetailSlice.reducer;
