import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchBookListAction, selectBookList, selectBookListLoading } from '../../features/bookList/bookListSlice';
import { MyPath } from '../../models';
import styles from './styles.module.scss'

const BookList: React.FC<any> = () => {
    const books = useAppSelector(selectBookList)
    const loading = useAppSelector(selectBookListLoading)
    const history = useHistory()
    const dispatch = useAppDispatch();
    const getBooks = () => {
        dispatch(fetchBookListAction(1))
    }
    const goToBookDetailPath = (id: string) => {
        history.push(MyPath.BookPage.replace(':id', id))
    }
    useEffect(() => {
        getBooks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <div className={styles.container}>
        <button onClick={() => getBooks()} className={styles.retrieveBookBtn}>Retrieve Books</button>
        {loading && <div className="loading">Loading...</div>}
        {!loading && <ol className={styles.books}>
                {books.map(book => {
                    return <li key={book.id} className={styles.book} onClick={() => goToBookDetailPath(book.id)}>
                        <div><b>{book.title}</b> by <em>{book.writer.name}</em></div>
                    </li>
                })}
            </ol>}
    </div>
}

export default BookList;