import React from "react";
import { IPagination } from "../../models/Pagination";

interface Props {
  pagination: IPagination
  onChange: (changedPage: number) => void
}
const Pagination: React.FC<Props> = ({ pagination, onChange }) => {
 
  return (
    <div className="pagination">
      <div className="pagination__first" onClick={() => onChange(pagination.firstPage)}>First</div>
      <div className="pagination__previous" onClick={() => onChange(pagination.previousPage)}>Previous</div>
      <div className="pagination__currentPage">{pagination.currentPage}</div>
      <div className="pagination__next" onClick={() => onChange(pagination.nextPage)}>Next</div>
      <div className="pagination__last" onClick={() => onChange(pagination.lastPage)}>Last</div>
    </div>
  );
};

export default Pagination;
