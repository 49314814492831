import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { MyPath } from "../../models";
import { selectChapterDetail } from "../chapterDetail/chapterDetailSlice";
import { selectShowButtonConfigurationStatus, selectShowFloatingChapterNavigator } from "../configuration/configurationSlice";
import {
  selectPreviousChapterId,
  selectNextChapterId,
  fetchChapterCheckAction,
} from "./chapterCheckSlice";
import styles from "./styles.module.scss";

const ChapterNavigation: React.FC<any> = () => {
  const history = useHistory();
  const showButtonConfiguration = useAppSelector(selectShowButtonConfigurationStatus);
  const showFloating = useAppSelector(selectShowFloatingChapterNavigator)

  const previousChapterId = useAppSelector(selectPreviousChapterId);
  const nextChapterId = useAppSelector(selectNextChapterId);
  const chapterDetail = useAppSelector(selectChapterDetail);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (chapterDetail && chapterDetail.book)
      dispatch(
        fetchChapterCheckAction({
          bookId: chapterDetail.book.id,
          chapterId: chapterDetail.id,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterDetail]);
  const goToPreviousChapter = () => {
    if (previousChapterId)
      history.push(MyPath.ChapterPage.replace(":id", previousChapterId));
  };
  const goToNextChapter = () => {
    if (nextChapterId)
      history.push(MyPath.ChapterPage.replace(":id", nextChapterId));
  };
  return (
    <Fragment>
      <div className={styles.floating} style={{ visibility: showButtonConfiguration ? 'visible' : 'hidden'}}>
        <div className={styles.left}>
          <span className={styles.symbol} onClick={goToPreviousChapter}>
            {"<"}
          </span>
        </div>
        <div className={styles.right}>
          <span className={styles.symbol} onClick={goToNextChapter}>
            {">"}
          </span>
        </div>
      </div>
      <div className={styles.static}>
        <button className={styles.previous} onClick={goToPreviousChapter}>Previous</button>
        <button className={styles.next} onClick={goToNextChapter}>Next</button>
      </div>
    </Fragment>
  );
};

export default ChapterNavigation;
