import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface ConfigurationState {
    showButton: boolean
    showPopup: boolean
    showChapterNavigator: boolean
    lineHeightPixel: number
    fontSizePixel: number
    letterSpacingPixel: number
    loaded: boolean
}

const initialState: ConfigurationState = {
    showButton: false,
    showPopup: false,
    showChapterNavigator: false,
    lineHeightPixel: 57, // 30
    fontSizePixel: 35, // 20
    letterSpacingPixel: 2,
    loaded: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getConfigurationFromLocalStorage = (): ConfigurationState => {
    try {
        const cached = localStorage.getItem('configuration')
        if (!cached || cached === null) {
            return initialState
        }
        const cachedData = JSON.parse(cached) as ConfigurationState
        return cachedData
    } catch (error) {
        return initialState
    }

}

const saveToLocalStorage = (state: any) => {
    localStorage.setItem('configuration', JSON.stringify(state))
}

const removeFromLocalStorage = () => {
    localStorage.removeItem('configuration')
}

const add = (originValue: number, addedValue: number) => {
    return originValue + addedValue;
}

const subtract = (originValue: number, subtractedValue: number) => {
    return originValue - subtractedValue;
}

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        toggleButtonConfigurationStatusAction: (state) => {
            state.showButton = !state.showButton
            saveToLocalStorage(state)
        },
        showButtonConfigurationAction: (state) => {
            state.showButton = true
            saveToLocalStorage(state)
        },
        hideButtonConfigurationAction: (state) => {
                state.showButton = false
            saveToLocalStorage(state)
        },
        showPopupConfigurationAction: (state) => {
            state.showPopup = true
            saveToLocalStorage(state)
        },
        hidePopupConfigurationAction: (state) => {
            state.showPopup = false
            saveToLocalStorage(state)
        },
        showFloatingChapterNavigatorAction: (state) => {
            state.showChapterNavigator = true
            saveToLocalStorage(state)
        },
        hideFloatingChapterNavigatorAction: (state) => {
            state.showChapterNavigator = false
            saveToLocalStorage(state)
        },
        increaseLineHeight: (state, action: PayloadAction<number>) => {
            state.lineHeightPixel = add(state.lineHeightPixel, action.payload);
            saveToLocalStorage(state)
        },
        decreaseLineHeight: (state, action: PayloadAction<number>) => {
            state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
            saveToLocalStorage(state)
        },
        increaseFontsize: (state, action: PayloadAction<number>) => {
            state.fontSizePixel = add(state.fontSizePixel, action.payload);
            saveToLocalStorage(state)
        },
        decreaseFontsize: (state, action: PayloadAction<number>) => {
            state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
            saveToLocalStorage(state)
        },
        increaseLetterSpacing: (state, action: PayloadAction<number>) => {
            state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
            saveToLocalStorage(state)
        },
        decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
            state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
            saveToLocalStorage(state)
        },
        setConfiguration: (state, action: PayloadAction<ConfigurationState>) => {
            state.fontSizePixel = action.payload.fontSizePixel
            state.letterSpacingPixel = action.payload.letterSpacingPixel
            state.lineHeightPixel = action.payload.lineHeightPixel
            state.showButton = false
            state.showPopup = false
            state.showChapterNavigator = false
            state.loaded = true
        },
        resetNavigationToDefaultAction: (state) => {
            state.fontSizePixel = initialState.fontSizePixel
            state.letterSpacingPixel = initialState.letterSpacingPixel
            state.lineHeightPixel = initialState.lineHeightPixel
            state.showButton = initialState.showButton
            state.showPopup = initialState.showPopup
            state.showChapterNavigator = initialState.showChapterNavigator
            state.loaded = true
            removeFromLocalStorage()
        }
    },
});

export const {
    toggleButtonConfigurationStatusAction,
    showButtonConfigurationAction,
    hideButtonConfigurationAction,
    showPopupConfigurationAction,
    hidePopupConfigurationAction,
    showFloatingChapterNavigatorAction,
    hideFloatingChapterNavigatorAction,
    increaseFontsize,
    increaseLetterSpacing,
    increaseLineHeight,
    decreaseFontsize,
    decreaseLetterSpacing,
    decreaseLineHeight,
    setConfiguration,
    resetNavigationToDefaultAction
} = configurationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectShowButtonConfigurationStatus = (state: RootState) => state.configuration.showButton;
export const selectShowPopupConfigurationStatus = (state: RootState) => state.configuration.showPopup;
export const selectShowFloatingChapterNavigator = (state: RootState) => state.configuration.showChapterNavigator;
export const selectFontsize = (state: RootState) => state.configuration.fontSizePixel;
export const selectLetterSpacing = (state: RootState) => state.configuration.letterSpacingPixel;
export const selectLineHeight = (state: RootState) => state.configuration.lineHeightPixel;
export const selectConfigurationLoaded = (state: RootState) => state.configuration.loaded;

export default configurationSlice.reducer;
