import React from 'react';
import { useParams } from 'react-router-dom';
import Chapter from '../../components/Chapter';
import ConfigurationComponent from '../../components/configuration';

const ChapterPage: React.FC<any> = () => {
    const params = useParams() as any;
    const { id } = params;
  
    if (!id) {
        return <div className="loading">No Chapter Id</div>
    }
    
    return (
        <div>
             <ConfigurationComponent />
             <Chapter chapterId={id} />
        </div>
    )
}

export default ChapterPage;