import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IChapter, IChapterCheckRequest, IChapterCheckResponse } from '../../models/Chapter';
import { IBaseGetResponse } from '../../models/Common';
import { AppConfig } from '../../config';
import axios from 'axios';
import { RootState } from '../../app/store';

export interface ChapterCheckState {
    response?: IBaseGetResponse<IChapterCheckResponse>
    check?: IChapterCheckResponse
    loading: boolean
}

const initialState: ChapterCheckState = {
    loading: false,
};
export const fetchChapterCheckAction = createAsyncThunk(
    'chapterCheck/fetch',
    async (input: IChapterCheckRequest, {rejectWithValue }) => {
        try {
            const { bookId, chapterId } = input;
            const response = await axios.get<IBaseGetResponse<IChapterCheckResponse>>(`${AppConfig.truyenApi}/check?book=${bookId}&chapter=${chapterId}`, {
                headers: {
                    token: localStorage.getItem('authentication')
                }
            })
            return response.data;    
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
  )

export const chapterCheckSlice = createSlice({
    name: 'chapterCheck',
    initialState,
    reducers: {
        // increaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = add(state.lineHeightPixel, action.payload);
        // },
        // decreaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
        // },
        // increaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = add(state.fontSizePixel, action.payload);
        // },
        // decreaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
        // },
        // increaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
        // },
        // decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChapterCheckAction.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(fetchChapterCheckAction.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.check = action.payload.result
        })
        builder.addCase(fetchChapterCheckAction.rejected, (state, action) => {
            state.loading = false;
            state.response = undefined;
            state.check = undefined;
        })
      },
});

export const selectChapterCheck = (state: RootState) => state.chapterCheck.check;
export const selectNextChapterId = (state: RootState) => state.chapterCheck.check?.next;
export const selectPreviousChapterId = (state: RootState) => state.chapterCheck.check?.previous;
export const selectChapterCheckLoading = (state: RootState) => state.chapterCheck.loading;

export default chapterCheckSlice.reducer;
