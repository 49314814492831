import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ChapterNavigation from "../../features/chapterCheck/ChapterNavigation";
import {
  fetchChapterDetailAction,
  selectChapterDetail,
  selectChapterDetailLoading,
} from "../../features/chapterDetail/chapterDetailSlice";
import {
  selectConfigurationLoaded,
  selectFontsize,
  selectLetterSpacing,
  selectLineHeight,
  selectShowButtonConfigurationStatus,
  toggleButtonConfigurationStatusAction,
} from "../../features/configuration/configurationSlice";
import Loading from "../Loading";
import styles from "./styles.module.scss";

interface Props {
  chapterId: string;
}

const Chapter: React.FC<Props> = ({ chapterId }) => {
  const detail = useAppSelector(selectChapterDetail);
  const loading = useAppSelector(selectChapterDetailLoading);
  const dispatch = useAppDispatch();
  const fontSize = useAppSelector(selectFontsize);
  const letterSpacing = useAppSelector(selectLetterSpacing);
  const lineHeight = useAppSelector(selectLineHeight);
  const configurationLoaded = useAppSelector(selectConfigurationLoaded)
  const showButtonConfigurationStatus = useAppSelector(selectShowButtonConfigurationStatus)
  const toPixel = (value: number) => {
    return `${value}px`;
  };
  useEffect(() => {
    dispatch(fetchChapterDetailAction(chapterId));
  }, [chapterId, dispatch]);
  return (
    <div className={styles.container}>
      {loading && <Loading />}
      {detail && (
        <div className={styles.header}>
          <div className={styles.bookInfo}>
            <div className={styles.title}>{detail.book.title}</div>
            <div className={styles.writer}>{detail.book.writer.name}</div>
          </div>
          <div className={styles.chapterInfo}>
            {`Chapter ${detail.chapter}: ${detail.title}`}
          </div>
        </div>
      )}
      {detail && <ChapterNavigation />}
      {detail && configurationLoaded && (
        <div className={styles.content} onClick={() => dispatch(toggleButtonConfigurationStatusAction())}>
          <div
            dangerouslySetInnerHTML={{ __html: detail.content }}
            style={{
              lineHeight: toPixel(lineHeight),
              fontSize: toPixel(fontSize),
              letterSpacing: toPixel(letterSpacing),
            }}
          />
        </div>
      )}
      {detail && <ChapterNavigation />}
    </div>
  );
};

export default Chapter;
