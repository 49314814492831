import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBook } from '../../models/Book';
import { IBaseGetResponse } from '../../models/Common';
import { AppConfig } from '../../config';
import axios from 'axios';
import { RootState } from '../../app/store';

export interface BookDetailState {
    response?: IBaseGetResponse<IBook>
    detail?: IBook
    loading: boolean
}

const initialState: BookDetailState = {
    loading: false,
};

export const fetchBookDetailAction = createAsyncThunk(
    'bookDetail/fetch',
    async (bookId: string, {rejectWithValue }) => {
        try {
            const response = await axios.get<IBaseGetResponse<IBook>>(`${AppConfig.truyenApi}/books/${bookId}`, {
                headers: {
                    token: localStorage.getItem('authentication')
                }
            })
            return response.data;    
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
  )

export const bookDetailSlice = createSlice({
    name: 'bookDetail',
    initialState,
    reducers: {
        // increaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = add(state.lineHeightPixel, action.payload);
        // },
        // decreaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
        // },
        // increaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = add(state.fontSizePixel, action.payload);
        // },
        // decreaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
        // },
        // increaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
        // },
        // decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBookDetailAction.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(fetchBookDetailAction.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.detail = action.payload.result;
        })
        builder.addCase(fetchBookDetailAction.rejected, (state, action) => {
            state.loading = false;
            state.response = undefined;
            state.detail = undefined;
        })
      },
});

export const selectBookDetail = (state: RootState) => state.bookDetail.detail;
export const selectBookDetailLoading = (state: RootState) => state.bookDetail.loading;

export default bookDetailSlice.reducer;
