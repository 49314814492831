import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBaseListResponse } from '../../models/Common';
import { AppConfig } from '../../config';
import axios from 'axios';
import { RootState } from '../../app/store';
import { IChapter } from '../../models/Chapter';
import { IPagination } from '../../models/Pagination';

export interface ChapterListState {
    response?: IBaseListResponse<IChapter>
    list: IChapter[]
    loading: boolean
    limit: number
    pagination: IPagination
}

const initialState: ChapterListState = {
    list: [],
    loading: false,
    limit: 10,
    pagination: {
        currentPage: 1,
        previousPage: 1,
        nextPage: 1,
        firstPage: 1,
        lastPage: 1
    }
};

export const fetchChapterListAction = createAsyncThunk(
    'chapterList/fetch',
    async (bookId: string, { getState, rejectWithValue }) => {
        try {
            const currentState = getState() as RootState;
            const { chapterList: { limit, pagination: { currentPage: page } } } = currentState;
            const response = await axios.get<IBaseListResponse<IChapter>>(`${AppConfig.truyenApi}/chapters`, {
                headers: {
                    token: localStorage.getItem('authentication')
                },
                params: {
                    limit,
                    page,
                    book: bookId 
                }
            })
            return response.data;    
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
  )

export const chapterListSlice = createSlice({
    name: 'chapterList',
    initialState,
    reducers: {
        updateChapterListLimitAction: (state, action: PayloadAction<number>) => {
            state.limit = action.payload;
        },
        updateChapterListCurrentPageAction: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload;
        }
        // decreaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
        // },
        // increaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = add(state.fontSizePixel, action.payload);
        // },
        // decreaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
        // },
        // increaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
        // },
        // decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChapterListAction.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(fetchChapterListAction.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.list = action.payload.result.items;
            state.pagination.lastPage = action.payload.result.totalPages;
            if (action.payload.result.nextPage !== null) {
                state.pagination.nextPage = action.payload.result.nextPage;
            } else {
                state.pagination.nextPage = 1;
            }
            if (action.payload.result.prevPage !== null) {
                state.pagination.previousPage = action.payload.result.prevPage;
            } else {
                state.pagination.previousPage = 1;
            }
            
        })
        builder.addCase(fetchChapterListAction.rejected, (state, action) => {
            state.loading = false;
            state.response = undefined;
            state.list = [];
        })
      },
});

export const { updateChapterListLimitAction, updateChapterListCurrentPageAction } = chapterListSlice.actions;

export const selectChapterList = (state: RootState) => state.chapterList.list;
export const selectChapterListLoading = (state: RootState) => state.chapterList.loading;
export const selectChapterListCurrentPage = (state: RootState) => state.chapterList.pagination.currentPage;
export const selectChapterListPagination = (state: RootState) => state.chapterList.pagination;

export default chapterListSlice.reducer;
