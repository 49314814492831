import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IChapter } from '../../models/Chapter';
import { IBaseGetResponse } from '../../models/Common';
import { AppConfig } from '../../config';
import axios from 'axios';
import { RootState } from '../../app/store';
import { goToTop } from '../../helpers';

export interface ChapterDetailState {
    response?: IBaseGetResponse<IChapter>
    detail?: IChapter
    loading: boolean
}

const initialState: ChapterDetailState = {
    loading: false,
};

export const fetchChapterDetailAction = createAsyncThunk(
    'chapterDetail/fetch',
    async (chapterId: string, {rejectWithValue }) => {
        try {
            const response = await axios.get<IBaseGetResponse<IChapter>>(`${AppConfig.truyenApi}/chapters/${chapterId}`, {
                headers: {
                    token: localStorage.getItem('authentication')
                }
            })
            return response.data;    
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
  )

export const chapterDetailSlice = createSlice({
    name: 'chapterDetail',
    initialState,
    reducers: {
        // increaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = add(state.lineHeightPixel, action.payload);
        // },
        // decreaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
        // },
        // increaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = add(state.fontSizePixel, action.payload);
        // },
        // decreaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
        // },
        // increaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
        // },
        // decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChapterDetailAction.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(fetchChapterDetailAction.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            const rawContent = action.payload.result.content;
            const splitter = '********************************************************************************************************'
            goToTop();
            if (rawContent.includes(splitter) && rawContent.split(splitter)[2]) {
                const anotherSplitter = `=====================================================================================================`
                const removeHeaderUnnecessary = rawContent.split(splitter)[2]
                const removeFooterUnnecessary = removeHeaderUnnecessary.split(anotherSplitter)[0]
                state.detail = {
                    ...action.payload.result,
                    content: removeFooterUnnecessary.replaceAll('\n', '<br>')
                }
            } else {
                state.detail = action.payload.result;
            }
        })
        builder.addCase(fetchChapterDetailAction.rejected, (state, action) => {
            state.loading = false;
            state.response = undefined;
            state.detail = undefined;
        })
      },
});

export const selectChapterDetail = (state: RootState) => state.chapterDetail.detail;
export const selectChapterDetailLoading = (state: RootState) => state.chapterDetail.loading;

export default chapterDetailSlice.reducer;
