import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useOnClickOutside,
} from "../../app/hooks";
import { Configuration } from "../../features/configuration/Configuration";
import { ReactComponent as ConfigurationSvg } from "../../assets/svg/configuration.svg";
import styles from "./styles.module.scss";
import Bookmark from "../../features/bookmarkChapter/Bookmark";
import {
  getConfigurationFromLocalStorage,
  hidePopupConfigurationAction,
  selectShowButtonConfigurationStatus,
  selectShowFloatingChapterNavigator,
  selectShowPopupConfigurationStatus,
  setConfiguration,
  showPopupConfigurationAction,
} from "../../features/configuration/configurationSlice";
import { goToBottom, goToTop } from "../../helpers";

const ConfigurationComponent: React.FC<any> = () => {
  const showButtonConfiguration = useAppSelector(selectShowButtonConfigurationStatus);
  const showPopupConfiguration = useAppSelector(selectShowPopupConfigurationStatus);
  const showFloating = useAppSelector(selectShowFloatingChapterNavigator);
  const dispatch = useAppDispatch();
  const configurationRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(configurationRef, () => {
    dispatch(hidePopupConfigurationAction());
  });
  useEffect(() => {
    dispatch(setConfiguration(getConfigurationFromLocalStorage()))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Fragment>
      <div className={styles.container} ref={configurationRef} style={{ visibility: showButtonConfiguration ? 'visible' : 'hidden' }}>
        <div
          className={styles.toggle}
          onClick={() => dispatch(showPopupConfigurationAction())}
        >
          <ConfigurationSvg className={styles.svg} />
        </div>
        {showPopupConfiguration && (
          <Fragment>
            <Configuration />
          </Fragment>
        )}
      </div>
      {!showPopupConfiguration && showButtonConfiguration && <Bookmark />}
      <div className={styles.goToBottom} onClick={goToBottom} style={{ visibility: showButtonConfiguration ? 'visible' : 'hidden' }}>
            <button className={styles.button}>{">"}</button>
          </div>
          <div className={styles.goToTop} onClick={goToTop} style={{ visibility: showButtonConfiguration ? 'visible' : 'hidden' }}>
            <button className={styles.button}>{"<"}</button>
          </div>
    </Fragment>
  );
};

export default ConfigurationComponent;
