import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchChapterListAction, selectChapterList, selectChapterListCurrentPage, selectChapterListLoading, selectChapterListPagination, updateChapterListCurrentPageAction } from '../../features/chapterList/chapterListSlice';
import { MyPath } from '../../models';
import Loading from '../Loading';
import Pagination from '../Pagination';
import styles from './styles.module.scss';

interface Props {
    bookId: string
}
const ChapterList: React.FC<Props> = ({ bookId }) => {
    const history = useHistory();
    const pagination = useAppSelector(selectChapterListPagination)
    const currentPage = useAppSelector(selectChapterListCurrentPage)
    const list = useAppSelector(selectChapterList)
    const loading = useAppSelector(selectChapterListLoading)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchChapterListAction(bookId))
    }, [bookId, currentPage, dispatch])
    const paginationChange = (changedPage: number) => {
        dispatch(updateChapterListCurrentPageAction(changedPage))
    }
    const goToChapterDetail = (chapterId: string) => {
        history.push(MyPath.ChapterPage.replace(':id', chapterId));
    }
    return <div className={styles.container}>
        <Pagination pagination={pagination} onChange={paginationChange} />
        {loading && <Loading />}
        {list.length === 0 &&  <div className="loading">No Chapter List</div>}
        {list.map(chapter => {
            return <div key={chapter.id} className={styles.chapter} onClick={() => goToChapterDetail(chapter.id)}>
        {`Chapter ${chapter.chapter}:  ${chapter.title}`}
                </div>
        })}
        <Pagination pagination={pagination} onChange={paginationChange} />
    </div>
}

export default ChapterList;