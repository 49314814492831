import React from 'react';
import { useParams } from 'react-router';
import Book from '../../components/Book';

const BookPage: React.FC<any> = () => {
    const { id: bookId } = useParams() as any
    if (!bookId) {
        return <div>No Book Id</div>
    }
    return (
        <div>
            <Book bookId={bookId} />
        </div>
    )
}

export default BookPage;