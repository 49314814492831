import React, { Fragment } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import Header from "../components/Header";
import { selectAuthStatus } from "../features/auth/authSlice";
import { MyPath } from "../models";
import BookPage from "./book";
import ChapterPage from "./chapter";
import HomePage from "./home";

const PageContainer: React.FC<any> = () => {
  const authenticated = useAppSelector(selectAuthStatus);
  const renderRoutes = () => {
    if (!authenticated) {
      return (
        <Route path={MyPath.HomePage}>
          <div style={{ textAlign: 'center', fontSize: '20px' }}>Login please</div>
        </Route>
      );
    }
    return (
      <Fragment>
        <Route exact path={MyPath.HomePage}>
          <HomePage />
        </Route>
        <Route path={MyPath.BookPage}>
          <BookPage />
        </Route>
        <Route path={MyPath.ChapterPage}>
          <ChapterPage />
        </Route>
      </Fragment>
    );
  };
  return (
    <div>
      <Router>
        <Header />
        <Switch>
          {renderRoutes()}
        </Switch>
      </Router>
    </div>
  );
};

export default PageContainer;
