import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchBookDetailAction, selectBookDetail, selectBookDetailLoading } from '../../features/bookDetail/bookDetailSlice';
import ChapterList from '../ChapterList';
import BookWriterInfo from './BookWriterInfo';
import LatestReadChapter from './LatestReadChapter';
import styles from './styles.module.scss';

interface Props {
    bookId: string
}
const Book: React.FC<Props> =({ bookId }) => {
    const dispatch = useAppDispatch();
    const bookDetail = useAppSelector(selectBookDetail)
    const loading = useAppSelector(selectBookDetailLoading)
    useEffect(() => {
        console.log(bookId)
        dispatch(fetchBookDetailAction(bookId))
    }, [bookId, dispatch])
    if (loading) {
        return <div className="loading">Loading...</div>
    }
    if (!bookDetail) {
        return <div className="loading">No Book's Detail</div>
    }
    
    return <div className={styles.container}>
        <div className={styles.bookInfo}>
        <div className={styles.title}>{bookDetail.title}</div>
        <div className={styles.description}>{bookDetail.description}</div>
        </div>
        
        <BookWriterInfo bookDetail={bookDetail} />
        <LatestReadChapter bookId={bookId} />
        {/* <button className={styles.readBtn} onClick={() => goToChapterDetailPath(1)}>Read From Chapter 1</button> */}
        <ChapterList bookId={bookId} />
    </div>
}

export default Book;