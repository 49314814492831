import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ReactComponent as MarkSvg } from "../../assets/svg/mark.svg";
import { selectChapterDetail } from "../chapterDetail/chapterDetailSlice";
import { implementBookmarkChapterAction } from "./bookmarkChapterSlice";
import styles from "./styles.module.scss";
const Bookmark: React.FC<any> = () => {
    const dispatch = useAppDispatch()
    const chapterDetail = useAppSelector(selectChapterDetail)
    const bookmark = () => {
        if (chapterDetail)
        dispatch(implementBookmarkChapterAction({
            book: chapterDetail.book.id,
            chapter: chapterDetail.id,
        }))
    }
  return (
    <div className={styles.container} onClick={bookmark}>
      <MarkSvg className={styles.svg} />
    </div>
  );
};

export default Bookmark;
