import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import styles from "./Configuration.module.scss";
import {
  decreaseFontsize,
  decreaseLetterSpacing,
  decreaseLineHeight,
  hideFloatingChapterNavigatorAction,
  increaseFontsize,
  increaseLetterSpacing,
  increaseLineHeight,
  resetNavigationToDefaultAction,
  selectFontsize,
  selectLetterSpacing,
  selectLineHeight,
  selectShowFloatingChapterNavigator,
  showFloatingChapterNavigatorAction,
} from "./configurationSlice";

export function Configuration() {
  const [showMore, setShowMore] = useState(false)

  const fontSize = useAppSelector(selectFontsize);
  const letterSpacing = useAppSelector(selectLetterSpacing);
  const lineHeight = useAppSelector(selectLineHeight);
  const showFloatingChapterNavigator = useAppSelector(
    selectShowFloatingChapterNavigator
  );
  const dispatch = useAppDispatch();

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <button
          className={styles.button}
          aria-label="Decrement value"
          onClick={() => dispatch(decreaseFontsize(1))}
        >
          -
        </button>
        <span className={styles.value}>Fontsize: {fontSize}px</span>
        <button
          className={styles.button}
          aria-label="Increment value"
          onClick={() => dispatch(increaseFontsize(1))}
        >
          +
        </button>
      </div>
      <div className={styles.row}>
        <button
          className={styles.button}
          aria-label="Decrement value"
          onClick={() => dispatch(decreaseLetterSpacing(1))}
        >
          -
        </button>
        <span className={styles.value}>Letter Spacing: {letterSpacing}px</span>
        <button
          className={styles.button}
          aria-label="Increment value"
          onClick={() => dispatch(increaseLetterSpacing(1))}
        >
          +
        </button>
      </div>
      <div className={styles.row}>
        <button
          className={styles.button}
          aria-label="Decrement value"
          onClick={() => dispatch(decreaseLineHeight(1))}
        >
          -
        </button>
        <span className={styles.value}>Line Height: {lineHeight}px</span>
        <button
          className={styles.button}
          aria-label="Increment value"
          onClick={() => dispatch(increaseLineHeight(1))}
        >
          +
        </button>
      </div>
      {/* <div className={styles.row}>
        {!showFloatingChapterNavigator && (
          <button
            className={styles.button}
            onClick={() => dispatch(showFloatingChapterNavigatorAction())}
          >
            Show Floating Navigator
          </button>
        )}
        {showFloatingChapterNavigator && (
          <button
            className={styles.button}
            onClick={() => dispatch(hideFloatingChapterNavigatorAction())}
          >
            Hide Floating Navigator
          </button>
        )}
      </div> */}
      <div className={styles.row} onClick={() => setShowMore(!showMore)}>
        <button className={`${styles.more}`}>{!showMore ? '>>' : '<<'}</button>
      </div>
      {showMore && <div className={styles.row}>
        <button
          className={`${styles.button} ${styles.reset}`}
          onClick={() => dispatch(resetNavigationToDefaultAction())}
        >
          Reset to default
        </button>
      </div>}
    </div>
  );
}
