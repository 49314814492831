import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchHistoryListAction, selectHistoryList } from '../../../features/history/historySlice';
import { MyPath } from '../../../models';
import { IChapter } from '../../../models/Chapter';
import styles from './styles.module.scss';
interface Props {
    bookId: string
}
const LatestReadChapter: React.FC<Props> = ({ bookId }) => {
    const chapters = useAppSelector(selectHistoryList)
    const history = useHistory()
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchHistoryListAction(bookId))
    }, [])
    if (chapters.length === 0) {
        return <div className={styles.container}>*</div>
    }
    const firstOne = chapters[0]?.chapter as IChapter | undefined
    const goToChapterDetail = (chapterId: string) => {
        history.push(MyPath.ChapterPage.replace(':id', chapterId))
    }
    return <div className={styles.container}>
        <div className={styles.header}>
            Bookmarked
        </div>
        {firstOne && <div className={styles.bookmarked} onClick={() => goToChapterDetail(firstOne.id)}>
        {`Chapter ${firstOne.chapter}: ${firstOne.title}`}
        </div>}
    </div>
}

export default LatestReadChapter;