import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuthStatus, updateAuthAction } from '../../features/auth/authSlice';
import { MyPath } from '../../models';
import styles from './styles.module.scss';

interface IProfile { 
    email: string;
    firstName: string;
    lastName: string;
    username: string;
    _id: string;
}

const Header: React.FC<any> = () => {
    const history = useHistory()
    const authenticated = useAppSelector(selectAuthStatus);
    const [token, setToken] = useState<string>();
    const [profile, setProfile] = useState<IProfile>();
    const dispatch = useAppDispatch();
    const login = () => {
        window.location.href = `https://sso.duc168.com/login?redirect=${window.location.href}`;
    }
    const logout = () => {
        dispatch(updateAuthAction(false))
        localStorage.removeItem('authentication');
    }
    const getProfile = () => {
        return axios.get(`https://sso.api.duc168.com/profile?token=${token}`);
    }
    const getSsoToken = () => {
        const query = window.location.search;
        const checkIfTokenIsInTheParams = query.includes('ssoToken=');
        if (checkIfTokenIsInTheParams) {
            const removeQuestionMarkAtTheBeginning = query.startsWith('?') ? query.slice(1) : query;
            const removeKey = removeQuestionMarkAtTheBeginning.split('ssoToken=')[1];
            const separatedSsoTokenFromOthers = removeKey.split('&')[0];
            const token = separatedSsoTokenFromOthers;
            return token;
        }
        return undefined;
    }
    const getVerifiedSsoToken = () => {
        const query = window.location.search;
        const checkIfTokenIsInTheParams = query.includes('verifiedSsoToken=');
        if (checkIfTokenIsInTheParams) {
            const removeQuestionMarkAtTheBeginning = query.startsWith('?') ? query.slice(1) : query;
            const removeKey = removeQuestionMarkAtTheBeginning.split('verifiedSsoToken=')[1];
            const separatedSsoTokenFromOthers = removeKey.split('&')[0];
            const token = separatedSsoTokenFromOthers;
            return token;
        }
        return undefined;
    }

    const saveTokenToCached = (token: string) => {
        const cached = localStorage.getItem('authentication');
        if (cached && cached === null && token) {
            // save token to cache if token is different
            const cached = localStorage.getItem('authentication');
            if (token !== cached) {
                localStorage.setItem('authentication', token)
                setToken(token);
            }
        } else {
            localStorage.setItem('authentication', token);
            setToken(token);
        }
    }
    useEffect(() => {
        const ssoToken = getSsoToken();
        if (ssoToken) {
            saveTokenToCached(ssoToken);
            dispatch(updateAuthAction(true))
            history.push(window.location.href.split('?')[0].replace(window.location.origin, ''))
        }
    }, [dispatch, history])

    useEffect(() => {
        const cached = localStorage.getItem('authentication');
        const verifiedSsoToken = getVerifiedSsoToken();
        
        if (cached && cached !== null && !verifiedSsoToken) {
            //verify
            window.location.href = `https://sso.duc168.com/verify?redirect=${window.location.href}`;
        }
        if (verifiedSsoToken) {
            saveTokenToCached(verifiedSsoToken);
            dispatch(updateAuthAction(true))
            // alert()
            // history.push(MyPath.HomePage)
            history.push(window.location.href.split('?')[0].replace(window.location.origin, ''))
        }
    }, [dispatch, history])
    useEffect(() => {
        if (token)
        getProfile().then(pro => {
            // console.log(pro);
            const profile = pro.data.data as IProfile;
            setProfile(profile);
        }).catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    return (
        <div className={styles.container}>
            <div className={styles.header}>
            <Link to={MyPath.HomePage}>duc168 truyen</Link>
            </div>
            {/* <ul className={styles.router}>
                <li><Link to={MyPath.HomePage}>Home</Link></li>
                <li><Link to={MyPath.BookPage}>Book</Link></li>
                <li><Link to={MyPath.ChapterPage}>Chapter</Link></li>
            </ul> */}
            {!authenticated && <div className={styles.profile}>
            <button className={styles.login} onClick={login}>
                Login
            </button>
                </div>}
            
            
            {(profile && authenticated) &&<div className={styles.profile}>
                <div className={styles.account}>
                   <span> User: </span>
                <i>{profile?.username}</i>
               
                </div>
                {authenticated && <button className={styles.logout} onClick={logout}>
                Logout
            </button>}

            </div>}
        </div>
    )
}

export default Header