import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBookmarkChapterRequest, IChapter } from '../../models/Chapter';
import { IBaseGetResponse } from '../../models/Common';
import { AppConfig } from '../../config';
import axios from 'axios';
import { RootState } from '../../app/store';
import { IHistory } from '../../models/History';

export interface BookmarkChapterState {
    response?: IBaseGetResponse<IHistory>
    detail?: IHistory
    loading: boolean
}

const initialState: BookmarkChapterState = {
    loading: false,
};

export const implementBookmarkChapterAction = createAsyncThunk(
    'bookmarkChapter/implement',
    async (input: IBookmarkChapterRequest, {rejectWithValue }) => {
        try {
            const response = await axios.post<IBaseGetResponse<IHistory>>(`${AppConfig.truyenApi}/histories`, input, {
                headers: {
                    token: localStorage.getItem('authentication')
                }
            })
            return response.data;    
        } catch (error) {
            console.log(error)
            return rejectWithValue(error)
        }
    }
  )

export const bookmarkChapterSlice = createSlice({
    name: 'bookmarkChapter',
    initialState,
    reducers: {
        // increaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = add(state.lineHeightPixel, action.payload);
        // },
        // decreaseLineHeight: (state, action: PayloadAction<number>) => {
        //     state.lineHeightPixel = subtract(state.lineHeightPixel, action.payload);
        // },
        // increaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = add(state.fontSizePixel, action.payload);
        // },
        // decreaseFontsize: (state, action: PayloadAction<number>) => {
        //     state.fontSizePixel = subtract(state.fontSizePixel, action.payload);
        // },
        // increaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = add(state.letterSpacingPixel, action.payload);
        // },
        // decreaseLetterSpacing: (state, action: PayloadAction<number>) => {
        //     state.letterSpacingPixel = subtract(state.letterSpacingPixel, action.payload);
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(implementBookmarkChapterAction.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(implementBookmarkChapterAction.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
            state.detail = action.payload.result;
        })
        builder.addCase(implementBookmarkChapterAction.rejected, (state, action) => {
            state.loading = false;
            state.response = undefined;
            state.detail = undefined;
        })
      },
});

export const selectBookmarkChapter = (state: RootState) => state.bookmarkChapter.detail;
export const selectBookmarkChapterLoading = (state: RootState) => state.bookmarkChapter.loading;

export default bookmarkChapterSlice.reducer;
